import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { MsalProvider } from '@azure/msal-react'
import './assets/scss/style.scss'
import BamContextProvider from './components/Contexts/BamContext/BamContextProvider'
import IM_ContextProvider from './components/Contexts/IM_Context/IM_ContextProvider'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse " data-testid="spinner"></div>
  </div>
)

const Home = React.lazy(() => import('./features/home/home'))

// Pages
const Login = React.lazy(() => import('./pages/login/Login'))
const Page404 = React.lazy(() => import('./pages/page404/Page404'))
const Page500 = React.lazy(() => import('./pages/page500/Page500'))

const App = ({ pca }) => {
  ;(function () {
    var script = document.createElement('script')
    var head = document.getElementsByTagName('head')[0]
    script.async = true
    script.type = 'text/javascript'
    script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-51632266-8'
    head.appendChild(script)
  })()

  window.dataLayer = window.dataLayer || []

  function gtag() {
    window.dataLayer.push(arguments)
  }
  gtag('js', new Date())
  gtag('config', 'UA-51632266-8', {
    anonymize_ip: true,
    allow_display_features: false,
    link_attribution: false,
  })

  return (
    <IM_ContextProvider>
      <BamContextProvider>
        <MsalProvider instance={pca}>
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/" name="Login" element={<Login />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route path="*" name="Home" element={<Home />} />
            </Routes>
          </Suspense>
        </MsalProvider>
      </BamContextProvider>
    </IM_ContextProvider>
  )
}

export default App
