import React, { useState } from 'react'
import IM_Context from './Im_Context'

const IM_ContextProvider = ({ children }) => {
  const [isDarkTheme, setIsDarkTheme] = useState(true)
  return (
    <IM_Context.Provider value={{ isDarkTheme, setIsDarkTheme }}>{children}</IM_Context.Provider>
  )
}

export default IM_ContextProvider
